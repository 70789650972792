import { useTranslation } from "react-i18next";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { PickupAndLoyaltyCard, } from "./types";
import { CollectGiftModal } from "./CollectGiftModal";
import { COMMEN_ITEMS, GIFTS_ITEMS, POINTS_ITEMS } from "../../constant";
import { GetKoinzContext } from "../../context/getKoinzContext";
import { scrollStepsSlider } from "../../helperFunctions/FirebaseAnalytics";

export const PickupAndLoyaltySection: React.FC = () => {
  const positionRef = useRef(0);
  const [isScrollHorizontal, setIsScrollHorizontal] = useState(false);

  const pickupAndLoyaltySectionData = useContext(GetKoinzContext);
  const {
    data: {
      has_gift = false,
    }
  } = pickupAndLoyaltySectionData;

  const {
    t,
  } = useTranslation();

  const COLLECT_GIFTS_INFO: PickupAndLoyaltyCard[] = useMemo(() => COMMEN_ITEMS.concat(has_gift ? GIFTS_ITEMS : POINTS_ITEMS), [has_gift]);

  const handleScrollHorizontal = (e: any) => {
    const scrollSection = e.currentTarget.scrollLeft;
    if (scrollSection !== positionRef.current) {
      positionRef.current = scrollSection;
      setIsScrollHorizontal(true);
    }
  };
  useEffect(() => {
    scrollStepsSlider(isScrollHorizontal)
  }, [isScrollHorizontal])
  return (
    <section
      className="bg-primary-contrast flex flex-col p-6 gap-2">
      <h1 className="text-primary-disabledValue font-noto font-bold">
        {has_gift ? t('getKoinzPickupAndLoyaltyStaticText') : t('getKoinzGetYourPointsStaticText')}
      </h1>
      <div
        onScroll={handleScrollHorizontal}

        className="overflow-x-scroll -mx-6 flex flex-row justify-start gap-2 snap-x snap-mandatory no-scrollbar" >
        <div className="w-4 h-40 shrink-0"></div>

        <CollectGiftModal
          collection={COLLECT_GIFTS_INFO}
        />
        <div className="w-4 h-40 shrink-0"></div>
      </div>

    </section>
  )
}