import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import images from "../../assets";
import { devices } from "../../constant";
import { GetKoinzContext } from "../../context/getKoinzContext";
import { beforeExpiration } from "../../helperFunctions/FirebaseAnalytics";
import { useDeviceType } from "../../hooks/useDeviceType";

export const WelcomeSection: React.FC = () => {
  const welcomeSectionData = useContext(GetKoinzContext);
  const deviceType = useDeviceType();

  const {
    data: {
      customer_name = '',
      customer_phone_number = '',
      store_image,
      points = 0,
      has_gift = false,
      store_ar_name = '',
      store_en_name = '',
    }
  } = welcomeSectionData;
  
  const {
    t,
    i18n: {
      dir,
      language
    }
  } = useTranslation();

  return (
    <section className="bg-secondary-default flex flex-col px-6 py-4 gap-4 rounded">

      <a
        href={devices.android ? process.env.REACT_APP_GOOGLE_PLAY : process.env.REACT_APP_APPLE_STORE}
        onClick={() => beforeExpiration(store_en_name, customer_phone_number, deviceType, has_gift, language)}
        className="bg-secondary-value flex flex-row justify-center items-center h-11 rounded gap-1">
        <img
          alt="bell_icon"
          src={images.bell_icon}
        />
        <h3 className="font-noto font-bold text-sm">{t('getKoinzbeforeExpirationStaticText')}</h3>
      </a>

      <div className="bg-primary-contrast p-4 rounded flex flex-col gap-2">
        <h1 className="font-noto font-bold text-xl">{t('getKoinzWelcomeStaticText')} {customer_name}!</h1>

        <h1 className="font-noto font-normal">{t('getKoinzPhoneNumberStaticText')}: {customer_phone_number}</h1>

        <div className="flex flex-row justify-start items-center gap-2 mt-3">
          <div className="w-9 h-9">
            <img
              src={store_image ? store_image : images.brand_placeholder}
              className="rounded-full"
              alt="store_image"
            />
          </div>
          {!has_gift && (
            <h3 className="text-sm font-noto font-normal">{t('getKoinzPointsStaticText')}
              <span className="text-surface-value font-noto font-bold"> {points} {t('getKoinzTitlePointsStaticText')}</span>
              {t('getKoinzUnlockGiftStaticText')} {dir() === 'ltr' ? store_en_name : store_ar_name} </h3>
          )}
          {has_gift && (
            <h3 className="text-sm font-noto font-normal">
              <span className="text-surface-value font-bold"> {t('getKoinzSubTitleUnlockGiftStaticText')}</span>
              {t('getKoinzUnlockGiftStaticText')} {dir() === 'ltr' ? store_en_name : store_ar_name} {t('getKoinzWatingGiftStaticText')}</h3>
          )}
        </div>
      </div>

    </section>
  )
}