import React from "react";
import { AppleStoreButton } from "../AppleStoreButton";
import { GooglePlayButton } from "../GooglePlayButton";
import { useTranslation } from "react-i18next";
import { devices } from "../../constant";

const neitherAndroidNorIphone = !devices.android && !devices.iphone;
const shouldShowAndroidButton = (devices.android && !devices.iphone) || neitherAndroidNorIphone;
const shouldShowIphoneButton = (!devices.android && devices.iphone) || neitherAndroidNorIphone;

export const DownloadAppBottomSheet: React.FC = () => {

  const {
    t,
  } = useTranslation();
  
  return (
    <footer className="bg-secondary-default sticky p-4 bottom-0 left-0 right-0 rounded-t-lg shadow-[0_2px_20px_rgba(28,28,30,0.3)] border-t-2 border-t-stone-300 mt-auto">
      <div className="flex justify-center flex-col items-center text-center gap-2">
        <h1 className="text-2xl	font-noto font-bold">{t('getKoinzDownloadAppNowStaticText')}</h1>
        <p className="text-primary-disabledValue text-sm font-noto font-normal">{t('getKoinzDownloadAppDescriptionStaticText')}</p>
      </div>
      {shouldShowAndroidButton && <GooglePlayButton />}
      {shouldShowIphoneButton && <AppleStoreButton />}
    </footer>
  )
}