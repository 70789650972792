import React, { useCallback, useContext, } from "react";
import { useTranslation } from "react-i18next";
import images from "../../assets";
import { GetKoinzContext } from "../../context/getKoinzContext";
import { Language } from "../../types";

export const Header: React.FC = () => {
  const headerData = useContext(GetKoinzContext);

  const {
    data: {
      store_color = '',
      store_image = '',
    }
  } = headerData;
  
  const {
    t,
    i18n: {
      language,
      changeLanguage,
      dir,
    },
  } = useTranslation();

  const toggleLanguage = useCallback(() => {
    changeLanguage(language === Language.AR ? Language.EN : Language.AR);
  }, [language])

  return (
    <header className="sticky top-0 flex items-center h-14 bg-primary-difault w-screen"
      style={{
        backgroundColor: store_color,
      }}>

      <div className="w-full h-full flex justify-center items-center">
        <img
          src={store_image ? store_image : images.brand_placeholder}
          className='w-10 h-10 rounded-full border-2'
          alt="store_image"
        />
      </div>

      <div className="absolute inset-0 justify-end flex items-center">
        <div
          className={`bg-surface-default p-1 px-2 ${dir() === 'ltr' ? 'rounded-l' : 'rounded-r'}`}
          onClick={toggleLanguage}>
          <h3 className="text-secondary-default font-noto font-bold text-sm">{t('getKoinzTranslationButtonStaticText')}</h3>
        </div>
      </div>

    </header>
  )
}