export interface GetKoinzData {
  store_image?: string;
  store_color?: string;
  store_en_name: string;
  store_ar_name: string;
  customer_name?: string;
  customer_phone_number: string;
  points?: number;
  gift_en_name?: string;
  gift_ar_name?: string;
  gift_image?: string;
  has_gift: boolean;
}

export enum DeviceType {
  ANDROID = 'android',
  IPHONE = 'iphone',
}
export enum Language {
  EN = 'en',
  AR = 'ar'
}