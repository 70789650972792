import { PickupAndLoyaltyCard } from './../components/PickupAndLoyaltySection/types';
import images from "../assets";
import { DeviceType } from '../types';

export enum AnalayticsEvents {
  SHOW_KOINZ_WELCOME_PAGE = "get_koinz_show_koinz_welcome_page",
  PRESS_DOWNLOAD_BUTTON = "get_koinz_press_download_button",
  PRESS_USE_BEFORE_EXPIRATION = "get_koinz_press_use_before_expiration",
  PRESS_DOWNLOAD_APP_CARD = "get_koinz_press_download_app_card",
  SCROLL_STEPS_SLIDER = "get_koinz_scroll_steps_slider",
}

export const devices = {
  android: navigator.userAgent.toLocaleLowerCase().includes(DeviceType.ANDROID),
  iphone: navigator.userAgent.toLocaleLowerCase().includes(DeviceType.IPHONE),
}

export const COMMEN_ITEMS: PickupAndLoyaltyCard[] = [
  {
    image: images.order,
    titleTranslationKey: 'getKoinzNearestSpotStaticText',
    downlaodApp: devices.android ? process.env.REACT_APP_GOOGLE_PLAY : process.env.REACT_APP_APPLE_STORE
  },
  {
    image: images.unlock_and_redeem_gift,
    titleTranslationKey: 'getKoinzRedeemGiftStaticText'
  },
];

export const GIFTS_ITEMS: PickupAndLoyaltyCard[] = [
  {
    image: images.add_gift,
    titleTranslationKey: 'getKoinzAddGiftToCardStaticText'
  },
  {
    image: images.collect_gift,
    titleTranslationKey: 'getKoinzCollectGiftsStaticText'
  },
];

export const POINTS_ITEMS: PickupAndLoyaltyCard[] = [
  {
    image: images.collect_gift,
    titleTranslationKey: 'getKointCollectPointsStaticText'
  },
  {
    image: images.git_points,
    titleTranslationKey: 'getKoinzCollectPointsStaticText'
  }
];

