import brand_placeholder from '../assets/images/brand_placeholder.png';
import bell_icon from '../assets/images/bell_icon.png';
import collect_gift from '../assets/images/collect_gift.png';
import order from '../assets/images/order.png';
import unlock_and_redeem_gift from '../assets/images/unlock_and_redeem_gift.png';
import apple_icon from '../assets/images/apple_icon.png';
import google_store_icon from '../assets/images/google_store_icon.png';
import add_gift from '../assets/images/add_gift.png';
import git_points from '../assets/images/git_points.png';

const images = {
  brand_placeholder,
  bell_icon,
  collect_gift,
  order,
  unlock_and_redeem_gift,
  apple_icon,
  google_store_icon,
  add_gift,
  git_points,
}
export default images