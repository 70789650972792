import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import { GetKoinzContext } from "../../../context/getKoinzContext";
import { downloadAppCard } from "../../../helperFunctions/FirebaseAnalytics";
import { useDeviceType } from "../../../hooks/useDeviceType";
import { CollectGiftModalProps } from "./types";

export const CollectGiftModal: React.FC<CollectGiftModalProps> = (props) => {
  const collectGiftModalData = useContext(GetKoinzContext);
  const deviceType = useDeviceType();

  const {
    collection,
  } = props;

  const {
    data: {
      has_gift,
      store_en_name,
      customer_phone_number,
    }
  } = collectGiftModalData;

  const {
    t,
    i18n: {
      dir,
      language
    },
  } = useTranslation();

  return (
    <>
      {collection.map((item, index) => (
        <a
          key={index}
          href={item?.downlaodApp}
          onClick={() => downloadAppCard(store_en_name, customer_phone_number, deviceType, has_gift, language)}
          className={`bg-secondary-default min-w-[40%] min-h-[165px] rounded-lg p-3 snap-start flex flex-col gap-3 ${dir() === 'ltr' ? 'scroll-ml-6' : 'scroll-mr-6'}`}>
          <div className="">
            <img
              src={item.image}
              alt={`${index} ${item.titleTranslationKey}`}
            />
          </div>
          <h1 className="font-noto font-normal text-sm">{t(item.titleTranslationKey)}</h1>
        </a>
      ))}
    </>
  )
}