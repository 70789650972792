// TODO i Will Add Context to ignore prop drilling 
import React, { useEffect, useState } from "react";
import { DownloadAppBottomSheet } from "../DownloadAppBottomSheet";
import { Header } from "../Header";
import { PickupAndLoyaltySection } from "../PickupAndLoyaltySection";
import { WelcomeSection } from "../WelcomeSection";
import queryString from 'query-string';
import { getCustomerWelcomePage } from "../../request";
import { GetKoinzData, } from "../../types";
import { showKoinzWelcomeScreen } from "../../helperFunctions/FirebaseAnalytics";
import { useTranslation } from "react-i18next";
import { GetKoinzContext } from "../../context/getKoinzContext";
import { useDeviceType } from "../../hooks/useDeviceType";
import { ClipLoader } from "react-spinners";


export const GetKoinzWrapper: React.FC = () => {
  let params = queryString.parse(window.location.search);
  const deviceType = useDeviceType();
  const [data, setData] = useState<GetKoinzData | null>(null);
  const [isLoading, setIsloading] = useState(false);

  const {
    i18n: {
      language,
    },
  } = useTranslation();

  const getData = async () => {
    const fetchResult = await fetch(`${process.env.REACT_APP_BASE_URL}${getCustomerWelcomePage}?id=${params.id}`);
    if (fetchResult.ok) {
      const response = await fetchResult.json();
      setData(response);
      showKoinzWelcomeScreen(response?.customer_phone_number, deviceType, language, response?.has_gift, response?.store_en_name,)
      setIsloading(true);
    } else {
      // Error logic
    }
  }

  useEffect(() => {
    getData();
  }, []);

  if (!isLoading) {
    return (
      <div className="flex justify-center items-center">
        <ClipLoader
          color="#FFA351"
        />
      </div>
    )
  }

  return (
    <GetKoinzContext.Provider
      value={{
        data: data as GetKoinzData
      }}
    >
      <Header />
      <WelcomeSection />
      <PickupAndLoyaltySection />
      <DownloadAppBottomSheet />
    </GetKoinzContext.Provider>
  )
}
