import i18next from "i18next";
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import translationAR from './ar.json';
import translationEN from './en.json';
const resources = {
  ar: {
    translation: translationAR
  },
  en: {
    translation: translationEN,
  },
};

i18next
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    fallbackLng: 'ar',
    lng: 'ar',
    resources,
  });
