import React, { useContext } from "react";
import { useTranslation } from "react-i18next";
import images from "../../assets";
import { GetKoinzContext } from "../../context/getKoinzContext";
import { downloadApp } from "../../helperFunctions/FirebaseAnalytics";
import { useDeviceType } from "../../hooks/useDeviceType";

export const GooglePlayButton: React.FC = () => {
  const downloadAppBottomSheetData = useContext(GetKoinzContext);
  const deviceType = useDeviceType();
  const {
    data: {
      store_en_name = '',
      has_gift = false,
      customer_phone_number = '',
    }
  } = downloadAppBottomSheetData

  const {
    t,
    i18n: {
      language,
    }
  } = useTranslation();
  
  return (
    <div dir="ltr" className="flex justify-center items-center mt-4">
      <a
        className="border border-secondary-disabledValue rounded-lg w-11/12 h-14 flex justify-center items-center gap-2"
        href={process.env.REACT_APP_GOOGLE_PLAY}
        onClick={() => downloadApp(store_en_name, customer_phone_number, deviceType, has_gift, language,)}
      >
        <img
          src={images.google_store_icon}
          alt="google_store_icon"
        />
        <div className="flex flex-col justify-center items-start">
          <h2 className="font-normal uppercase text-xs leading-none">{t('getKoinzGooglePlaySubTitleStaticText')}</h2>
          <h1 className="font-bold">{t('getKoinzGooglePlayStaticText')}</h1>
        </div>
      </a>
    </div>
  )
}