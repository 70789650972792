import { getAnalytics, logEvent } from 'firebase/analytics';
import { AnalayticsEvents } from '../../constant';

export const FirebaseAnalytics = {
  logEvent(EventName: string, EventParams?: Record<string, any>) {
    const analytics = getAnalytics();
    logEvent(analytics, EventName, EventParams);
  }
}

export const showKoinzWelcomeScreen = (phone_number?: string, device_type?: string, lang?: string, has_gift?: boolean, brand_name?: string) => {
  FirebaseAnalytics.logEvent(AnalayticsEvents.SHOW_KOINZ_WELCOME_PAGE, { phone_number, device_type, lang, has_gift, brand_name });
}

export const downloadApp = (brand_name?: string, phone_number?: string, device_type?: string, has_gift?: boolean, lang?: string,) => {
  FirebaseAnalytics.logEvent(AnalayticsEvents.PRESS_DOWNLOAD_BUTTON, { brand_name, phone_number, device_type, has_gift, lang });
}

export const beforeExpiration = (brand_name?: string, phone_number?: string, device_type?: string, has_gift?: boolean, lang?: string,) => {
  FirebaseAnalytics.logEvent(AnalayticsEvents.PRESS_USE_BEFORE_EXPIRATION, { brand_name, phone_number, device_type, has_gift, lang });
}
export const downloadAppCard = (brand_name?: string, phone_number?: string, device_type?: string, has_gift?: boolean, lang?: string,) => {
  FirebaseAnalytics.logEvent(AnalayticsEvents.PRESS_DOWNLOAD_APP_CARD, { brand_name, phone_number, device_type, has_gift, lang });
}
export const scrollStepsSlider = (isScrollHorizontal: boolean) => {
  FirebaseAnalytics.logEvent(AnalayticsEvents.SCROLL_STEPS_SLIDER, { isScrollHorizontal });
}