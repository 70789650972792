import { useTranslation } from 'react-i18next';
import { GetKoinzWrapper } from './components/GetKoinzWrapper';

function App() {
  const {
    i18n: {
      language,
      dir,
    },
  } = useTranslation();

  return (
    <div
      dir={dir(language)}
      className='flex justify-center flex-col min-h-screen'>
      <GetKoinzWrapper />
    </div>
  );
}

export default App;
